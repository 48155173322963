(function(window) { var svs = window.svs || {}; svs.modules = svs.modules || []; if(svs.modules.indexOf('/components/sportinfo/config-builder/assets/javascripts/tab-config-builder.js') >= 0) return;  svs.modules.push('/components/sportinfo/config-builder/assets/javascripts/tab-config-builder.js');
"use strict";

function ownKeys(e, r) { var t = Object.keys(e); if (Object.getOwnPropertySymbols) { var o = Object.getOwnPropertySymbols(e); r && (o = o.filter(function (r) { return Object.getOwnPropertyDescriptor(e, r).enumerable; })), t.push.apply(t, o); } return t; }
function _objectSpread(e) { for (var r = 1; r < arguments.length; r++) { var t = null != arguments[r] ? arguments[r] : {}; r % 2 ? ownKeys(Object(t), !0).forEach(function (r) { _defineProperty(e, r, t[r]); }) : Object.getOwnPropertyDescriptors ? Object.defineProperties(e, Object.getOwnPropertyDescriptors(t)) : ownKeys(Object(t)).forEach(function (r) { Object.defineProperty(e, r, Object.getOwnPropertyDescriptor(t, r)); }); } return e; }
function _defineProperty(e, r, t) { return (r = _toPropertyKey(r)) in e ? Object.defineProperty(e, r, { value: t, enumerable: !0, configurable: !0, writable: !0 }) : e[r] = t, e; }
function _toPropertyKey(t) { var i = _toPrimitive(t, "string"); return "symbol" == typeof i ? i : i + ""; }
function _toPrimitive(t, r) { if ("object" != typeof t || !t) return t; var e = t[Symbol.toPrimitive]; if (void 0 !== e) { var i = e.call(t, r || "default"); if ("object" != typeof i) return i; throw new TypeError("@@toPrimitive must return a primitive value."); } return ("string" === r ? String : Number)(t); }
function _classPrivateFieldInitSpec(e, t, a) { _checkPrivateRedeclaration(e, t), t.set(e, a); }
function _checkPrivateRedeclaration(e, t) { if (t.has(e)) throw new TypeError("Cannot initialize the same private elements twice on an object"); }
function _classPrivateFieldGet(s, a) { return s.get(_assertClassBrand(s, a)); }
function _classPrivateFieldSet(s, a, r) { return s.set(_assertClassBrand(s, a), r), r; }
function _assertClassBrand(e, t, n) { if ("function" == typeof e ? e === t : e.has(t)) return arguments.length < 3 ? t : n; throw new TypeError("Private element is not present on this object"); }

const {
  matchState: {
    SportinfoMatchState
  },
  constants: {
    SportinfoPathNames
  }
} = svs.components.sportinfo.common;
const SportinfoPathNamesValues = Object.values(SportinfoPathNames);
var _id = new WeakMap();
var _matchStates = new WeakMap();
class TabConfigBuilder {
  constructor(id) {
    _classPrivateFieldInitSpec(this, _id, void 0);
    _classPrivateFieldInitSpec(this, _matchStates, void 0);
    if (!SportinfoPathNamesValues.includes(id)) {
      throw new Error('Invalid SportinfoComponent');
    }
    _classPrivateFieldSet(_id, this, id);
    _classPrivateFieldSet(_matchStates, this, {
      [SportinfoMatchState.NotStarted]: undefined,
      [SportinfoMatchState.Ongoing]: undefined,
      [SportinfoMatchState.Finished]: undefined
    });
  }
  getId() {
    return _classPrivateFieldGet(_id, this);
  }
  hasMatchState(matchState) {
    return Boolean(_classPrivateFieldGet(_matchStates, this)[matchState]);
  }

  set(matchState, content) {
    if (!SportinfoMatchState[matchState]) {
      throw new Error('Invalid param matchState');
    }
    if (!content || !Array.isArray(content)) {
      throw new Error('Invalid param content');
    }
    _classPrivateFieldGet(_matchStates, this)[matchState] = [...content];
    return this;
  }

  setMany(matchStates, content) {
    if (!matchStates || !Array.isArray(matchStates)) {
      throw new Error('Invalid param matchStates');
    }
    for (const matchState of matchStates) {
      this.set(matchState, content);
    }
    return this;
  }

  setAll(content) {
    if (!content || !Array.isArray(content)) {
      throw new Error('Invalid param content');
    }
    _classPrivateFieldGet(_matchStates, this)[SportinfoMatchState.NotStarted] = [...content];
    _classPrivateFieldGet(_matchStates, this)[SportinfoMatchState.Ongoing] = [...content];
    _classPrivateFieldGet(_matchStates, this)[SportinfoMatchState.Finished] = [...content];
    return this;
  }

  prepend(matchState, content) {
    if (!SportinfoMatchState[matchState]) {
      throw new Error('Invalid param matchState');
    }
    if (!content || !Array.isArray(content)) {
      throw new Error('Invalid param content');
    }
    _classPrivateFieldGet(_matchStates, this)[matchState].unshift(...content);
    return this;
  }

  prependMany(matchStates, content) {
    if (!matchStates || !Array.isArray(matchStates)) {
      throw new Error('Invalid param matchState');
    }
    if (!content || !Array.isArray(content)) {
      throw new Error('Invalid param content');
    }
    for (const matchState of matchStates) {
      _classPrivateFieldGet(_matchStates, this)[matchState].unshift(...content);
    }
    return this;
  }

  prependAll(content) {
    if (!content || !Array.isArray(content)) {
      throw new Error('Invalid param content');
    }
    _classPrivateFieldGet(_matchStates, this)[SportinfoMatchState.NotStarted].unshift(...content);
    _classPrivateFieldGet(_matchStates, this)[SportinfoMatchState.Ongoing].unshift(...content);
    _classPrivateFieldGet(_matchStates, this)[SportinfoMatchState.Finished].unshift(...content);
    return this;
  }

  append(matchState, content) {
    if (!SportinfoMatchState[matchState]) {
      throw new Error('Invalid param matchState');
    }
    if (!content || !Array.isArray(content)) {
      throw new Error('Invalid param content');
    }
    _classPrivateFieldGet(_matchStates, this)[matchState].push(...content);
    return this;
  }

  appendMany(matchStates, content) {
    if (!matchStates || !Array.isArray(matchStates)) {
      throw new Error('Invalid param matchState');
    }
    if (!content || !Array.isArray(content)) {
      throw new Error('Invalid param content');
    }
    for (const matchState of matchStates) {
      _classPrivateFieldGet(_matchStates, this)[matchState].push(...content);
    }
    return this;
  }

  appendAll(content) {
    if (!content || !Array.isArray(content)) {
      throw new Error('Invalid param content');
    }
    _classPrivateFieldGet(_matchStates, this)[SportinfoMatchState.NotStarted].push(...content);
    _classPrivateFieldGet(_matchStates, this)[SportinfoMatchState.Ongoing].push(...content);
    _classPrivateFieldGet(_matchStates, this)[SportinfoMatchState.Finished].push(...content);
    return this;
  }
  build() {
    return _objectSpread({}, _classPrivateFieldGet(_matchStates, this));
  }
}
setGlobal('svs.components.sportinfo.configBuilder.TabConfigBuilder', TabConfigBuilder);

 })(window);