(function(window) { var svs = window.svs || {}; svs.modules = svs.modules || []; if(svs.modules.indexOf('/components/sportinfo/config-builder/assets/javascripts/config-builder.js') >= 0) return;  svs.modules.push('/components/sportinfo/config-builder/assets/javascripts/config-builder.js');
"use strict";

function ownKeys(e, r) { var t = Object.keys(e); if (Object.getOwnPropertySymbols) { var o = Object.getOwnPropertySymbols(e); r && (o = o.filter(function (r) { return Object.getOwnPropertyDescriptor(e, r).enumerable; })), t.push.apply(t, o); } return t; }
function _objectSpread(e) { for (var r = 1; r < arguments.length; r++) { var t = null != arguments[r] ? arguments[r] : {}; r % 2 ? ownKeys(Object(t), !0).forEach(function (r) { _defineProperty(e, r, t[r]); }) : Object.getOwnPropertyDescriptors ? Object.defineProperties(e, Object.getOwnPropertyDescriptors(t)) : ownKeys(Object(t)).forEach(function (r) { Object.defineProperty(e, r, Object.getOwnPropertyDescriptor(t, r)); }); } return e; }
function _defineProperty(e, r, t) { return (r = _toPropertyKey(r)) in e ? Object.defineProperty(e, r, { value: t, enumerable: !0, configurable: !0, writable: !0 }) : e[r] = t, e; }
function _toPropertyKey(t) { var i = _toPrimitive(t, "string"); return "symbol" == typeof i ? i : i + ""; }
function _toPrimitive(t, r) { if ("object" != typeof t || !t) return t; var e = t[Symbol.toPrimitive]; if (void 0 !== e) { var i = e.call(t, r || "default"); if ("object" != typeof i) return i; throw new TypeError("@@toPrimitive must return a primitive value."); } return ("string" === r ? String : Number)(t); }
function _classPrivateFieldInitSpec(e, t, a) { _checkPrivateRedeclaration(e, t), t.set(e, a); }
function _checkPrivateRedeclaration(e, t) { if (t.has(e)) throw new TypeError("Cannot initialize the same private elements twice on an object"); }
function _classPrivateFieldGet(s, a) { return s.get(_assertClassBrand(s, a)); }
function _classPrivateFieldSet(s, a, r) { return s.set(_assertClassBrand(s, a), r), r; }
function _assertClassBrand(e, t, n) { if ("function" == typeof e ? e === t : e.has(t)) return arguments.length < 3 ? t : n; throw new TypeError("Private element is not present on this object"); }

const {
  constants: {
    SportinfoMenuItems
  },
  matchState: {
    SportinfoMatchState
  }
} = svs.components.sportinfo.common;
const {
  TabConfigBuilder
} = svs.components.sportinfo.configBuilder;
var _tabConfigBuilders = new WeakMap();
var _menuItemOverrides = new WeakMap();
var _defaultRoutes = new WeakMap();
class ConfigBuilder {
  constructor() {
    _classPrivateFieldInitSpec(this, _tabConfigBuilders, void 0);
    _classPrivateFieldInitSpec(this, _menuItemOverrides, void 0);
    _classPrivateFieldInitSpec(this, _defaultRoutes, void 0);
    _classPrivateFieldSet(_tabConfigBuilders, this, []);
    _classPrivateFieldSet(_menuItemOverrides, this, {});
    _classPrivateFieldSet(_defaultRoutes, this, {});
  }

  addTab(menuItemKey) {
    let buildTab = arguments.length > 1 && arguments[1] !== undefined ? arguments[1] : () => {};
    let menuItemOverride = arguments.length > 2 && arguments[2] !== undefined ? arguments[2] : undefined;
    if (!SportinfoMenuItems[menuItemKey]) {
      throw new Error('Invalid param menuItemKey, not a valid value');
    }
    const newTabConfigBuilder = new TabConfigBuilder(menuItemKey);
    if (menuItemOverride) {
      _classPrivateFieldGet(_menuItemOverrides, this)[menuItemKey] = menuItemOverride;
    }
    buildTab(newTabConfigBuilder);
    _classPrivateFieldGet(_tabConfigBuilders, this).push(newTabConfigBuilder);
    return this;
  }

  setDefaultRoutes(menuItem, matchStates) {
    if (!SportinfoMenuItems[menuItem]) {
      throw new Error('Invalid param menuItem, not a valid value');
    }
    if (!matchStates || !Array.isArray(matchStates)) {
      throw new Error('Invalid param matchStates');
    }
    const currentTabs = _classPrivateFieldGet(_tabConfigBuilders, this).reduce((acc, currentTab) => [...acc, currentTab.getId()], []);
    if (!currentTabs.some(tab => tab === menuItem)) {
      throw new Error('Invalid param menuItem, must be an already added menu item');
    }
    matchStates.forEach(matchState => {
      _classPrivateFieldGet(_defaultRoutes, this)[matchState] = menuItem;
    });
    return this;
  }
  build() {
    const content = _classPrivateFieldGet(_tabConfigBuilders, this).reduce((acc, currentTab) => {
      acc[currentTab.getId()] = currentTab.build();
      return acc;
    }, {});

    const menuItems = Object.values(SportinfoMatchState).reduce((acc, currentValue) => {
      acc[currentValue] = [];
      for (const tabConfigBuilder of _classPrivateFieldGet(_tabConfigBuilders, this)) {
        if (tabConfigBuilder.hasMatchState(currentValue)) {
          const menuItemId = tabConfigBuilder.getId();
          let menuItemConfig = SportinfoMenuItems[menuItemId];

          if (_classPrivateFieldGet(_menuItemOverrides, this)[menuItemId]) {
            menuItemConfig = _objectSpread(_objectSpread({}, menuItemConfig), _classPrivateFieldGet(_menuItemOverrides, this)[menuItemId]);
          }
          acc[currentValue].push(menuItemConfig);
        }
      }
      return acc;
    }, {});
    return {
      content,
      menuItems,
      defaultRoutes: _classPrivateFieldGet(_defaultRoutes, this)
    };
  }
}
setGlobal('svs.components.sportinfo.configBuilder.ConfigBuilder', ConfigBuilder);

 })(window);